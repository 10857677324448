<template>
    <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">
        <div style="display: flex;justify-content: space-between;">
            <div class="tag-container">
                <el-select v-model="selectedBrand" placeholder="Filter by Brand and Type" class="brand-select" @change="handleBrandChange">
                    <el-option
                        label="All Programs"
                        value="All"
                    ></el-option>
                    <el-option
                        v-for="(brand, index) in brandList"
                        :key="index"
                        :label="index"
                        :value="brand"
                    ></el-option>
                </el-select>
                <el-select v-if="selectedBrand !== 'All' && trackList.hasOwnProperty(selectedBrand)" v-model="selectedTrack" placeholder="Filter by Track" class="track-select" @change="handleTrackChange">
                    <el-option
                        label="All Tracks"
                        value="All"
                    ></el-option>
                    <el-option
                        v-for="(track, index) in trackList[selectedBrand]"
                        :key="index"
                        :label="index"
                        :value="track"
                    ></el-option>
                </el-select>
                <el-date-picker
                    v-model="selectedDDLMonth"
                    type="month"
                    placeholder="Filter by registration deadline (Month/Year)"
                    format="MMMM yyyy"
                    value-format="yyyy-MM"
                    class="month-picker"
                    style="width: 300px;"
                    @change="handleMonthChange"
                >
                </el-date-picker>
                <!-- <el-select v-model="selectedSubject" placeholder="Filter by Subject" class="subject-select" @change="handleSubjectChange">
                    <el-option
                        label="All Subjects"
                        value="All"
                    ></el-option>
                    <el-option
                        v-for="(subject, index) in subjectList"
                        :key="index"
                        :label="subject"
                        :value="subject"
                    ></el-option>
                </el-select>
                <el-select v-model="selectedTeamType" placeholder="Filter by Individual/Team" class="team-type-select" @change="handleTeamTypeChange">
                    <el-option
                        label="Both Individual and Team"
                        value="Both"
                    ></el-option>
                    <el-option
                        label="Individual Participation only"
                        value="Individual"
                    ></el-option>
                    <el-option
                        label="Team Participation only"
                        value="Team"
                    ></el-option>
                </el-select> -->
                <!-- <div v-for="(tag, index) in allTags" :key="index" @click="handleSelectTag(tag)">
                    <p :class="selectedTag == tag ? 'tag-item-selected tag-item-normal' : 'tag-item-normal'">{{ tag }}
                    </p>
                </div> -->
            </div>
            <el-input 
                v-model="searchProject" 
                style="width: 320px;" 
                @keyup.enter.native="handleSearch" 
                placeholder="Search"
                @keydown.enter.prevent
            >
                <template #suffix>
                    <el-button 
                        class="clear-button" 
                        icon="el-icon-close" 
                        @click="clearSearch" 
                        v-if="searchProject" 
                        size="small" 
                        style="
                            color: grey; 
                            border: none; 
                            background: none; 
                            position: absolute; 
                            top: 22%; 
                            transform: translateY(-50%); 
                            right: 10px;
                        "
                    ></el-button>
                </template>
            </el-input>

        </div>
        <div v-if="!dataList || dataList.length === 0" class="no-data-placeholder">
            <el-empty description="No projects found">
                <template #image>
                    <!-- <img src="../../assets/image/no-data.png" alt="No data" style="width: 100px; height: 100px;"> -->
                </template>
                <template #description>
                    <p>No projects match your search criteria.</p>
                </template>
            </el-empty>
        </div>
        <div style="margin-top: 20px;">
            <div v-for="(project, index) in filteredList" :key="index" class="product-item"
                @click="showProjectDetail(project._id.$id)">
                <img :src="project.project_logo" class="product_logo">
                <div class="item-second">
                    <p class="product_name">{{ project.project_ename }}</p>
                    <div class="tag-list" v-if="project.tags && project.tags.length > 0">
                        <p class="product_tag" v-for="(tag, index) in project.tags.split(',')" :key="index">{{ tag }}
                        </p>
                    </div>
                </div>
                <div class="product_ddl">
<!--                    <img src="../../assets/image/icon_time1.png" style="width: 16px;height: 16px;margin-right: 5px;">-->
                    <template v-if="$dayjs(project.deadline_date).isBefore($dayjs())">
                        <p style="color: gray;">Closed</p>
                    </template>
                    <template v-else>
                        <p>Sign up before <br/> {{ $dayjs(project.deadline_date).format("MMM DD, YYYY") }}</p>
                    </template>
                </div>
                <div class="product_intro">
                    {{ project.short_intro }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
    getStoreProjects,postRequest
} from '../../api/eae'
import '../../assets/common/common.css'
import '../../assets/common/font.css'
export default ({
    name: 'index',
    data() {
        return {
            searchProject: '',
            dataList: [],
            filteredList:[],
            allTags: ['All', 'Olympiad', 'PBL', 'Business', 'Individual', 'Team'],
            selectedTag: 'All',
            brandList: {
                'World Academic Olympics (WAO) - Academic Contests': 'WAO',
                'Next Idea Matters (NIM) - Innovation Projects': 'NIM',
                'Global Youth Pulses (GYP) - Writing & Publications': 'GYP',
                'International Championships & Olympiads - Academic Contests': 'ICS',
                'EAE Awards - Review & Recognition': 'Review'
            },
            trackList:{
                'WAO':{
                    'Business Track':'Business',
                    'Economics Track':'Economics',
                    'Science Track':'Science',
                    'Social Science Track':'Social Science',
                    'Technology & Computer Science Track':'Computer Science',
                    'Life Science Track':'Life Science',
                    'Engineering Track':'Engineering',
                    'Mathematics Track':'Mathematics',
                    'Arts & Media & Design Track':'Arts & Media & Design',
                    'Interdisciplinary X-athlons Track':'Interdisciplinary X-athlons',
                    'Academic Relay Track':'Academic Relay',
                    'Academic Marathon Track':'Academic Marathon',
                },
                'NIM':{
                    'Next Sustainable Idea (NSI)':'NSI',
                    'Next Responsible Idea (NRI)':'NRI',
                    'Next Idea to Probe (NIP)':'NIP',
                    'Next Idea to Research (NIR)':'NIR',
                    'Next Idea to Advocate (NIA)':'NIA',
                    'Next Idea to Illuminate (NIL)':'NIL',
                    'Next Idea to Venture (NIV)':'NIV',
                    'Next Idea to Invent (NII)':'NII',
                    'Next Idea to Ecoplore (NIE)':'NIE',
                    
                },
                
                'ICS':{
                    'World Economics Cup (WEC)':'WEC',
                    'International Psychology Olympiad (IPsyO)':'IPsyO',
                    'International Business Olympiad (IBO)':'IBO',
                    'International Environmental Olympiad (IEnvO)':'IEnvO',
                    'International Finance Olympiad (IFO)':'IFO',
                },
                'Review':{
                    'EAE Awards':'EAE',
                    'World Student Publication Review (WSPR)':'WSPR'
                }
            },
            subjectList: ['Business','Economics','Science','Social Science','Computer Science','Life Science','Engineering','Mathematics','Art & Media & Design'],
            selectedBrand: 'All',
            selectedTrack: 'All',
            selectedSubject: 'All',
            selectedTeamType: 'Both',
            selectedDDLMonth: null
        }
    },
    mounted() {
        this.fetchData(true)
    },
    methods: {

        formatTags(project) {
            let tempTag = ''
            if (project.tags && project.tags != '') {
                let tempsTags = project.tags.split(',')
                for (let tag of tempsTags) {
                    tempTag += tag + ' '
                }
            }
            return tempTag
        },

        showProjectDetail(id) {
            this.$router.push({
                path: '/home/store/project',
                query: {
                    project_id: id
                }
            })
        },

        fetchData(showLoading) {
            let loading = null
            if (showLoading) {
                loading = this.$loading({
                    lock: true,
                    text: "Loading default data",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            let key_tag = ''
            getStoreProjects(key_tag,'student').then((res) => {
                let result = res.data
                this.dataList = result
                this.filteredList = result
                if (loading) {
                    loading.close();
                }
            })
        },

        clearSearch() {
            this.searchProject = '';
            this.fetchData(false);
            
        },
        handleSearch() {
            let loading = this.$loading({
                lock: true,
                text: "Searching...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            postRequest({
                function: 'searchStoreProjects',
                search_word: this.searchProject,
            }).then((res) => {
                loading.close();
                this.filteredList = res.data
            })
        },
        handleBrandChange(value) {
            this.selectedBrand = value
            this.selectedTrack = 'All'
            this.filterData()
        },
        handleSubjectChange(value) {
            this.selectedSubject = value
            this.filterData()
        },
        handleTeamTypeChange(value) {
            this.selectedTeamType = value
            this.filterData()
        },
        handleTrackChange(value) {
            this.selectedTrack = value
            this.filterData()
        },
        handleMonthChange(value) {
            this.selectedDDLMonth = value
            this.filterData()
        },
        filterData(){
            console.log('Filter Working')
            this.filteredList = this.dataList.filter(item => {
                const brandFilter = this.selectedBrand === 'All' || item.project_BU === this.selectedBrand;
                const trackFilter = this.selectedBrand === 'All' || 
                    (this.selectedBrand === 'WAO' ? 
                        (this.selectedTrack === 'All' || item.track === this.selectedTrack) :
                        (this.selectedTrack === 'All' || item.product_abbreciation === this.selectedTrack));
                
                const deadlineFilter = !this.selectedDDLMonth || 
                    (item.deadline_date && this.$dayjs(item.deadline_date).format('YYYY-MM') === this.selectedDDLMonth);

                return brandFilter && trackFilter && deadlineFilter;
            });
        }
    }
})
</script>
<style scoped lang="scss">
/deep/ .el-input__inner {
    border-radius: 5px;
    background-color: #f5f5f5;
}

.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .tag-item-normal {
        padding: 0px 6px;
        min-width: 85px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        background-color: #f5f5f5;
        color: #999;
        font-size: 14px;
        font-family: centurygothic_bold;
        cursor: pointer;
        border: 1px solid white;
    }

    .tag-item-selected {
        background-color: #FFF8F7;
        color: var(--color-primary);
        border: 1px solid #FF6450;
    }
}

.product-item {
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #E6D7D7;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;

    .product_logo {
        width: 80px;
        height: 80px;
        object-fit: cover;
    }

    .item-second {
        padding: 0px 15px;
        box-sizing: border-box;
        flex: 2;

        .product_name {
            color: #333;
            font-size: 14px;
            font-family: centurygothic_bold;
        }

        .tag-list{
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          margin-top: 8px;
        }
        .product_tag {
            padding: 2px 4px;
            display: inline-block;
            background-color: #FFF2CC;
            box-sizing: border-box;
            color: #333;
            font-size: 12px;
            line-height: 22px;
            font-family: centurygothic;
        }
    }


    .product_ddl {
        flex: 1;
        display: flex;
        align-items: center;
        width: 200px;
        color: #FC6556;
        font-size: 14px;
        font-family: centurygothic_bold;
    }

    .product_intro {
        flex: 3;
        height: 100%;
        color: #666;
        font-size: 12px;
        line-height: 20px;
        font-family: centurygothic;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-overflow: ellipsis;
    }

}
</style>